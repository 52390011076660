import UrlApi from "../../config/Url";
import { PostFunction } from "../../config/axios_connection";

export const GetCategory =
  ({ callback }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: {},
        Route: UrlApi.category.get,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };
export const GetBrands =
  ({ callback }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: {},
        Route: UrlApi.brands.get,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const GetFeatuerdItem =
  ({ callback }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: {},
        Route: UrlApi.item.getFeaturedProduct,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };
export const GetHotItem =
  ({ callback, type }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: { type },
        Route: UrlApi.item.getHotProduct,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };
export const GetSliderShow =
  ({ callback }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: { is_active: 1 },
        Route: UrlApi.slidershow.get,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const GetFavorites = () => async (dispatch, getState) => {
  try {
    const response = await PostFunction({
      data: {},
      Route: UrlApi.favorite.get,
      showalert: false,
      state: getState,
      isMultipart: false,
      dispatch: dispatch,
    });
    dispatch({
      type: "products/addFavorite",
      payload: { itemData: response?.data ?? [], dispatch },
    });
  } catch (err) {}
};
export const ManageFavorite = async ({ callback, product_id, is_add }) => {
  try {
    const response = await PostFunction({
      data: { product_id, is_add },
      Route: UrlApi.favorite.manage,
      showalert: false,
      isMultipart: false,
    });
    callback(response);
  } catch (err) {
    callback(false);
  }
};
