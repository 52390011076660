import { FaTrash } from "react-icons/fa";

const AddressComponent = ({
  data,
  canDelete,
  onClick,
  onDelete,
  nameButton = "Edit Address",
}) => {
  return (
    <div className="col-lg-6">
      <div className="myaccount-content">
        <div
          className="row"
          style={{
            justifyContent: "space-between",
            marginLeft: "0.1%",
            marginTop: "1%",
          }}
        >
          <h4 className="title">{data?.name}</h4>
          {canDelete && (
            <button className="icon-button" onClick={onDelete}>
              <FaTrash /> Delete
            </button>
          )}
        </div>
        <div className="billing_address">
          <h5>
            <strong>{data?.city_name}</strong>
          </h5>
          <p>
            {data?.street}, {data?.buildingName}, {data?.floor}
          </p>
          <button
            onClick={onClick}
            className="theme-btn-one bg-black btn_sm mt-4"
          >
            {nameButton}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddressComponent;
