import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import Cookie from "../Cookie";
import NewsletterModal from "../NewsletterModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Logo from "../../logo";
import { Subscription } from "../action";

const Footer = () => {
  let dispatch = useDispatch();
  let appsettings = useSelector((state) => state.settings.appsettings);
  let promoCenter = useSelector((state) => state.settings.promoCenter);
  let promoStatus = useSelector((state) => state.settings.promoStatus);
  let stopPromo = useSelector((state) => state.settings.stopPromo);
  let cookie = useSelector((state) => state.settings.cookie);
  let stopCookie = useSelector((state) => state.settings.stopCookie);

  useEffect(() => {
    if (promoStatus) {
      return;
    } else {
      dispatch({ type: "settings/promoStatus" });
      setTimeout(function () {
        dispatch({ type: "settings/promoCenter" });
      }, 2000);
    }

    if (stopCookie) {
      return;
    } else {
      setTimeout(function () {
        dispatch({ type: "settings/cookie" });
      }, 6000);
    }
  }, [dispatch, promoStatus, stopCookie]);

  const startPromoModal = () => {
    if (stopPromo) {
      dispatch({ type: "settings/promoCenter" });
      return;
    } else {
      dispatch({ type: "settings/promoCenter" });
      setTimeout(function () {
        dispatch({ type: "settings/promoCenter" });
      }, 700000);
    }
  };

  const stopPromoModal = () => {
    dispatch({ type: "settings/stopPromo" });
  };

  const cancelCookie = () => {
    dispatch({ type: "settings/cookie" });
  };

  const acceptCookie = () => {
    dispatch({ type: "settings/cookie" });
  };

  return (
    <>
      <footer id="footer_one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="footer_left_side">
                <Link to="/">
                  <Logo />
                </Link>
                <p>
                  <strong>ALL★STAR SPORT</strong> is the “one stop shop” for all
                  your sporting goods and equipment. One of the leading Sports
                  Retailer & Wholesaler in Lebanon, ALL★STAR SPORT offers you
                  the highest quality in the market for the lowest price
                </p>
                <div className="footer_left_side_icon">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={appsettings?.facebook_link}
                      >
                        <i
                          className="fa fa-facebook"
                          style={{ fontSize: 30 }}
                        ></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={appsettings?.instgram_link}
                      >
                        <i
                          className="fa fa-instagram"
                          style={{ fontSize: 30 }}
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              {FooterData.slice(0, 1).map((data, index) => (
                <div className="footer_one_widget" key={index}>
                  <h3>{data.title}</h3>
                  <ul>
                    {data.links.map((link, index) => (
                      <li key={index}>
                        <Link to={link.link}>{link.linkTitle}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
              {FooterData.slice(1, 2).map((data, index) => (
                <div className="footer_one_widget" key={index}>
                  <h3>{data.title}</h3>
                  <ul>
                    {data.links.map((link, index) => (
                      <li key={index}>
                        <Link to={link.link}>{link.linkTitle}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div> */}
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="footer_one_widget">
                <h3>NEWSLETTER</h3>
                <div id="mc_embed_signup" className="subscribe-form">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      dispatch(
                        Subscription({
                          email: e.target.EMAIL.value,
                          callback: (data) => {
                            if (data) {
                              Swal.fire(
                                "Success",
                                "Thank you for your Subscribtion",
                                "success"
                              );
                              document.querySelector(
                                "input[type='email']"
                              ).value = "";
                            }
                          },
                        })
                      );
                    }}
                  >
                    <div className="mc-form">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Your Mail"
                        name="EMAIL"
                        defaultValue=""
                        required
                      />
                      <div className="clear">
                        <button
                          className="theme-btn-one btn_md"
                          type="submit"
                          name="subscribe"
                          defaultValue=""
                        >
                          {" "}
                          Send Mail
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="go-top active"
          style={{ zIndex: 999999 }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <i className="fa fa-chevron-up"></i>
          <i className="fa fa-arrow-up"></i>
        </div>
      </footer>

      <section id="copyright_one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="copyright_left">
                <h6>
                  © CopyRight {new Date().getFullYear()}
                  {"  "}
                  <span>ALL★STAR SPORT</span>
                </h6>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="copyright_right">
                {/* <img src={payment} alt="img" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {cookie ? <Cookie accept={acceptCookie} cancel={cancelCookie} /> : null}
      {/* <NewsletterModal
        show={promoCenter}
        stop={stopPromoModal}
        start={startPromoModal}
      /> */}
    </>
  );
};

export default Footer;
