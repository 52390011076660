import UrlApi from "../../config/Url";
import { PostFunction } from "../../config/axios_connection";

export const Subscription =
  ({ callback, email }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: { email },
        Route: UrlApi.subscription.subscription,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };
