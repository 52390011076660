import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TopHeader from "./TopHeader";
import { useHistory, useLocation } from "react-router-dom";
import svg from "../../../assets/img/svg/cancel.svg";
import svgsearch from "../../../assets/img/svg/search.svg";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../logo";
import ImageWithLoadingError from "../../image/ImageWithLoadingError";
import {
  CartManagement,
  dataLocalStorage,
  getLocalStorage,
  isValidArray,
} from "../../../helper/publicFunction";

const Header = () => {
  const [click, setClick] = useState(false);
  const [show, setShow] = useState();
  const [searchText, setSearchText] = useState("");
  let appsettings = useSelector((state) => state.settings.appsettings);

  const history = useHistory();
  let user = getLocalStorage(dataLocalStorage.userinfo);

  let carts = useSelector((state) => state.products.carts);
  let favorites = useSelector((state) => state.products.favorites);
  let dispatch = useDispatch();
  const location = useLocation();

  const rmCartProduct = async (element) => {
    const newItem = await CartManagement({
      Dataitem: element,
      quantity: 1,
      size: element?.size,
      add: false,
    });
    dispatch({ type: "products/addCarts", payload: { newData: newItem } });
  };

  const rmFavProduct = (itemData) => {
    dispatch({
      type: "products/manageFavorite",
      payload: { itemData, dispatch },
    });
  };

  const cartTotal = () => {
    return carts.reduce(function (total, item) {
      return total + (item.quantity || 1) * item.price;
    }, 0);
  };

  const handleClick = () => {
    if (click) {
      document.querySelector("#offcanvas-add-cart").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#offcanvas-add-cart").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };
  const handleWish = () => {
    if (click) {
      document.querySelector("#offcanvas-wishlish").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#offcanvas-wishlish").style =
        "transform: translateX(0);";
    }
    setClick(!click);
  };

  const handleSearch = () => {
    if (click) {
      document.querySelector("#search").style =
        "transform: translate(-100%, 0); opacity: 0";
    } else {
      document.querySelector("#search").style =
        "transform: translate(0px, 0px); opacity: 1";
    }
    setClick(!click);
  };
  const handleabout = () => {
    if (click) {
      document.querySelector("#offcanvas-about").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#offcanvas-about").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };
  const handlemenu = () => {
    if (click) {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };

  const handleShow = (value) => {
    value === show ? setShow("") : setShow(value);
  };

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <>
      <TopHeader />
      <header className="header-section d-none d-xl-block">
        <div className="header-wrapper">
          <div className="header-bottom section-fluid sticky-header">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="header-logo">
                    <div className="logo">
                      <Link to="/">
                        <Logo customStyle={{ height: 100, width: 100 }} />
                      </Link>
                    </div>
                  </div>
                  <div className="main-menu menu-color--black menu-hover-color--golden d-none d-xl-block">
                    <nav>
                      <ul>
                        {/* {MenuData.map((item, index) => (
                          <NaveItems item={item} key={index} />
                        ))} */}
                      </ul>
                    </nav>
                  </div>

                  <ul className="header-action-link action-color--black action-hover-color--golden">
                    {user?.id && (
                      <li>
                        {favorites.length ? (
                          <a
                            href="#offcanvas-wishlish"
                            className="offcanvas-toggle"
                            onClick={handleWish}
                          >
                            <i className="fa fa-heart"></i>
                            <span className="item-count">
                              {favorites.length}
                            </span>
                          </a>
                        ) : (
                          <a
                            href="#offcanvas-wishlish"
                            className="offcanvas-toggle"
                          >
                            <i className="fa fa-heart"></i>
                            <span className="item-count">
                              {favorites.length}
                            </span>
                          </a>
                        )}
                      </li>
                    )}
                    <li>
                      {carts.length ? (
                        <a
                          href="#!"
                          className="offcanvas-toggle"
                          onClick={handleClick}
                        >
                          <i className="fa fa-shopping-bag"></i>
                          <span className="item-count">{carts.length}</span>
                        </a>
                      ) : (
                        <a href="#!" className="offcanvas-toggle">
                          <i className="fa fa-shopping-bag"></i>
                          <span className="item-count">{carts.length}</span>
                        </a>
                      )}
                    </li>
                    <li>
                      <a
                        href="#search"
                        className="search_width"
                        onClick={handleSearch}
                      >
                        <img src={svgsearch} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#offcanvas-about"
                        className="offacnvas offside-about offcanvas-toggle"
                        onClick={handleabout}
                      >
                        <i className="fa fa-bars"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-header sticky-header  section-fluid d-lg-block d-xl-none">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="mobile-header-left">
                <ul className="mobile-menu-logo">
                  <li>
                    <Link to="/">
                      <div className="logo">
                        <Logo customStyle={{ height: 70, width: 70 }} />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="mobile-right-side">
                <ul className="header-action-link action-color--black action-hover-color--golden">
                  <li>
                    <a
                      href="#!"
                      className="search_width"
                      onClick={handleSearch}
                    >
                      <img src={svgsearch} alt="img" />
                    </a>
                  </li>
                  {user?.id && (
                    <li>
                      {favorites.length ? (
                        <a
                          href="#offcanvas-wishlish"
                          className="offcanvas-toggle"
                          onClick={handleWish}
                        >
                          <i className="fa fa-heart"></i>
                          <span className="item-count">{favorites.length}</span>
                        </a>
                      ) : (
                        <a
                          href="#offcanvas-wishlish"
                          className="offcanvas-toggle"
                        >
                          <i className="fa fa-heart"></i>
                          <span className="item-count">{favorites.length}</span>
                        </a>
                      )}
                    </li>
                  )}
                  <li>
                    {carts.length ? (
                      <a
                        href="#!"
                        className="offcanvas-toggle"
                        onClick={handleClick}
                      >
                        <i className="fa fa-shopping-bag"></i>
                        <span className="item-count">{carts.length}</span>
                      </a>
                    ) : (
                      <a href="#!" className="offcanvas-toggle">
                        <i className="fa fa-shopping-bag"></i>
                        <span className="item-count">{carts.length}</span>
                      </a>
                    )}
                  </li>
                  <li>
                    <a
                      href="#!"
                      className="offcanvas-toggle offside-menu"
                      onClick={handlemenu}
                    >
                      <i className="fa fa-bars"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="mobile-menu-offcanvas"
        className="offcanvas offcanvas-rightside offcanvas-mobile-menu-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handlemenu}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="offcanvas-mobile-menu-wrapper">
          {/* <div className="mobile-menu-bottom">
            <div className="offcanvas-menu">
              <ul>
                <li>
                  <a href="#!" onClick={() => handleShow("home")}>
                    <span>Home</span>
                  </a>
                  {show === "home" ? (
                    <ul className="mobile-sub-menu">
                      <li>
                        <Link to="/">Fashion</Link>
                      </li>
                      <li>
                        <Link to="/furniture">Furniture</Link>
                      </li>
                      <li>
                        <Link to="/electronics">Electronics</Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <a href="#!" onClick={() => handleShow("shop")}>
                    <span>Shop</span>
                  </a>
                  {show === "shop" ? (
                    <>
                      <ul className="mobile-sub-menu">
                        <li>
                          <a href="#!">Shop Layout</a>
                          <ul className="mobile-sub-menu">
                            <li>
                              <Link to="/shop">Shop Four Grid</Link>
                            </li>
                            <li>
                              <Link to="/shopTwo">Shop Three Grid</Link>
                            </li>
                            <li>
                              <Link to="/shoplist">Shop List View</Link>
                            </li>
                            <li>
                              <Link to="/shop-left-bar">Shop Left Sidebar</Link>
                            </li>
                            <li>
                              <Link to="/shop-right-bar">
                                Shop Right Sidebar
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>

                      <ul className="mobile-sub-menu">
                        <li>
                          <a href="#!">Shop Pages</a>
                          <ul className="mobile-sub-menu">
                            <li>
                              <Link to="/cart">Cart View One</Link>
                            </li>
                            <li>
                              <Link to="/cartTwo">Cart View Two </Link>
                            </li>
                            <li>
                              <Link to="/empty-cart">Empty Cart</Link>
                            </li>
                            <li>
                              <Link to="/checkout">Checkout View One</Link>
                            </li>
                            <li>
                              <Link to="/checkout-two">Checkout View Two</Link>
                            </li>
                            <li>
                              <Link to="/wishlist">Wishlist</Link>
                            </li>
                            <li>
                              <Link to="/compare">Compare</Link>
                            </li>
                            <li>
                              <Link to="/order-tracking">Order Tracking</Link>
                            </li>
                            <li>
                              <Link to="/order-complete">Order Complete</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="mobile-sub-menu">
                        <li>
                          <a href="#!">Product Single</a>
                          <ul className="mobile-sub-menu">
                            <li>
                              <Link to="/item/1">
                                Product Single
                              </Link>
                            </li>
                            <li>
                              <Link to="/product-details-two/1">
                                Product Single Two
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </>
                  ) : null}
                </li>
                <li>
                  <a href="#!" onClick={() => handleShow("feature")}>
                    <span>Feature</span>
                  </a>
                  {show === "feature" ? (
                    <ul className="mobile-sub-menu">
                      <li>
                        <Link to="/product-hover">Product Hover</Link>
                      </li>
                      <li>
                        <Link to="/order-success">Order Success</Link>
                      </li>
                      <li>
                        <Link to="/email-template-one">Email Template 1</Link>
                      </li>
                      <li>
                        <Link to="/email-template-two">Email Template 2</Link>
                      </li>
                      <li>
                        <Link to="/email-template-three">Email Template 3</Link>
                      </li>
                      <li>
                        <Link to="/lookbooks">LookBook</Link>
                      </li>
                      <li>
                        <Link to="/invoice-one">Invoice 1</Link>
                      </li>
                      <li>
                        <Link to="/invoice-two">Invoice 2</Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <a href="#!" onClick={() => handleShow("blogs")}>
                    <span>Blogs</span>
                  </a>
                  {show === "blogs" ? (
                    <ul className="mobile-sub-menu">
                      <li>
                        <Link to="/blog-grid-three">Blog Grid View One</Link>
                      </li>
                      <li>
                        <Link to="/blog-grid-two">Blog Grid View Two</Link>
                      </li>
                      <li>
                        <Link to="/blog-list-view">Blog List View</Link>
                      </li>
                      <li>
                        <Link to="/blog-single-one">Blog Single View One </Link>
                      </li>
                      <li>
                        <Link to="/blog-single-two">Blog Single View TWo</Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <a href="#!" onClick={() => handleShow("pages")}>
                    <span>Pages</span>
                  </a>

                  {show === "pages" ? (
                    <ul className="mobile-sub-menu">
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/vendor-dashboard">Vendor</Link>
                      </li>
                      <li>
                        <Link to="/my-account">My Account</Link>
                      </li>
                      <li>
                        <Link to="/contact-one">Contact Us One</Link>
                      </li>
                      <li>
                        <Link to="/contact-two">Contact Us Two</Link>
                      </li>
                      <li>
                        <Link to="/coming-soon">Coming Soon</Link>
                      </li>
                      <li>
                        <Link to="/faqs">Frequently Questions</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/error">404 Page</Link>
                      </li>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
              </ul>
            </div>
          </div> */}
          <div className="mobile-contact-info">
            <div className="logo">
              <Link to="/">
                <Logo customStyle={{ height: 200 }} />
              </Link>
            </div>
            <address className="address">
              <span>
                Address: {appsettings?.country},{appsettings?.city},
                {appsettings?.street},{appsettings?.building}
              </span>
              <span>
                Call Us: {appsettings?.phone_number},
                {appsettings?.phone_number_2}
              </span>
              <span>Email: {appsettings?.email}</span>
            </address>
            <ul className="social-link">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={appsettings?.facebook_link}
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={appsettings?.instgram_link}
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
            <ul className="user-link">
              {user?.id && (
                <li>
                  <Link to="/wishlist">Wishlist</Link>
                </li>
              )}
              <li>
                <Link to="/cart">Cart</Link>
              </li>
              <li>
                <Link to="/checkout">Checkout</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        id="offcanvas-about"
        className="offcanvas offcanvas-rightside offcanvas-mobile-about-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleabout}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="mobile-contact-info">
          <address className="address">
            <Logo customStyle={{ height: 200 }} />
            <span>
              Address: {appsettings?.country},{appsettings?.city},
              {appsettings?.street},{appsettings?.building}
            </span>
            <span>
              Call Us: {appsettings?.phone_number},{appsettings?.phone_number_2}
            </span>
            <span>Email: {appsettings?.email}</span>
          </address>
          <ul className="social-link">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={appsettings?.facebook_link}
              >
                <i className="fa fa-facebook"></i>
              </a>
            </li>

            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={appsettings?.instgram_link}
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
          </ul>
          <ul className="user-link">
            {user?.id && (
              <li>
                <Link to="/wishlist">Wishlist</Link>
              </li>
            )}
            <li>
              <Link to="/cart">Cart</Link>
            </li>
            <li>
              <Link to="/checkout">Checkout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="offcanvas-add-cart"
        className="offcanvas offcanvas-rightside offcanvas-add-cart-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleClick}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="offcanvas-add-cart-wrapper">
          <h4 className="offcanvas-title">Shopping Cart</h4>
          <ul className="offcanvas-cart">
            {carts.map((data, index) => (
              <li className="offcanvas-wishlist-item-single" key={index}>
                <div className="offcanvas-wishlist-item-block">
                  <Link
                    to={`/item/${data.id}`}
                    className="offcanvas-wishlist-item-image-link"
                  >
                    <ImageWithLoadingError
                      src={
                        isValidArray(data?.image)
                          ? data?.image[0].image
                          : data?.image
                      }
                      alt="img"
                      className="offcanvas-wishlist-image"
                      withOrginalClass={false}
                    />
                  </Link>
                  <div className="offcanvas-wishlist-item-content">
                    <Link
                      to={`/item/${data.id}`}
                      className="offcanvas-wishlist-item-link"
                    >
                      {data.name}
                    </Link>
                    <div className="offcanvas-wishlist-item-details">
                      <span className="offcanvas-wishlist-item-details-quantity">
                        {data.quantity || 1} x
                      </span>
                      <span className="offcanvas-wishlist-item-details-price">
                        {" "}
                        ${data.price}
                      </span>
                    </div>
                    <span className="offcanvas-wishlist-item-details-price">
                      <b>Size </b>
                      {data.size.name}
                    </span>
                  </div>
                </div>
                <div className="offcanvas-wishlist-item-delete text-right">
                  <a
                    href="#!"
                    className="offcanvas-wishlist-item-delete"
                    onClick={() => rmCartProduct(data)}
                  >
                    {data.quantity > 1 ? (
                      <i className="fa fa-minus"></i>
                    ) : (
                      <i className="fa fa-trash"></i>
                    )}
                  </a>
                </div>
              </li>
            ))}
          </ul>
          <div className="offcanvas-cart-total-price">
            <span className="offcanvas-cart-total-price-text">Subtotal:</span>
            <span className="offcanvas-cart-total-price-value">
              ${cartTotal()}.00
            </span>
          </div>
          <ul className="offcanvas-cart-action-button">
            <li>
              <Link
                to="/cart"
                className="theme-btn-one btn-black-overlay btn_md"
              >
                View Cart
              </Link>
            </li>
            <li>
              <Link
                to="/checkout"
                className="theme-btn-one btn-black-overlay btn_md"
              >
                Checkout
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="offcanvas-wishlish"
        className="offcanvas offcanvas-rightside offcanvas-add-cart-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleWish}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="offcanvas-wishlist-wrapper">
          <h4 className="offcanvas-title">Wishlist</h4>

          <ul className="offcanvas-wishlist">
            {favorites.map((data, index) => (
              <li className="offcanvas-wishlist-item-single" key={index}>
                <div className="offcanvas-wishlist-item-block">
                  <Link
                    to={`/item/${data.id}`}
                    className="offcanvas-wishlist-item-image-link"
                  >
                    <ImageWithLoadingError
                      src={
                        isValidArray(data?.image)
                          ? data?.image[0].image
                          : data?.image
                      }
                      alt="img"
                      className="offcanvas-wishlist-image"
                      withOrginalClass={false}
                    />
                  </Link>
                  <div className="offcanvas-wishlist-item-content">
                    <Link
                      to={`/item/${data.id}`}
                      className="offcanvas-wishlist-item-link"
                    >
                      {data.name}
                    </Link>
                  </div>
                </div>
                <div className="offcanvas-wishlist-item-delete text-right">
                  <a
                    href="#!"
                    className="offcanvas-wishlist-item-delete"
                    onClick={() => rmFavProduct(data)}
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </div>
              </li>
            ))}
          </ul>
          <ul className="offcanvas-wishlist-action-button">
            <li>
              <Link
                to="/wishlist"
                className="theme-btn-one btn-black-overlay btn_md"
              >
                View wishlist
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div id="search" className="search-modal">
        <button type="button" className="close" onClick={handleSearch}>
          <img src={svg} alt="icon" />
        </button>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();

            toast.success("Check out the Results", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            if (location.pathname == "/items") {
              window.history.replaceState(
                {},
                "",
                `${window.location.pathname}?page_number=1&search=${searchText}`
              );
              window.location.reload(true);
            } else {
              history.push(`/items?page_number=1&search=${searchText}`);
            }
          }}
        >
          <input
            type="search"
            placeholder="type keyword(s) here"
            required
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
          <button type="submit" className="btn btn-lg btn-main-search">
            Search
          </button>
        </form>
      </div>
    </>
  );
};

export default Header;
