import { createSlice } from "@reduxjs/toolkit";
// Demo Data
import { ProductData } from "../data/productsData";
import {
  dataLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  saveLocalStorage,
} from "../../helper/publicFunction";
import { ManageFavorite } from "../../page/home/actions";
import { toast } from "react-hot-toast";

// Product Slice
const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: ProductData,
    carts: getLocalStorage(dataLocalStorage.card, true),
    favorites: getLocalStorage(dataLocalStorage.favorite, true),
    compare: getLocalStorage(dataLocalStorage.compare, true),
    single: null,
  },
  reducers: {
    // Add to Compare
    addToComp: (state, action) => {
      if (state.compare.length >= 3) {
        toast.error("Compare List is Full", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      let id = action.payload.item.id;
      // Check existance
      let item = state.compare.find((i) => i.id === parseInt(id));
      if (item === undefined) {
        // Get Product
        let arr = action.payload.item;
        state.compare.push(arr);
        saveLocalStorage(dataLocalStorage.compare, state.compare);
        toast.success("Successfully added to Compare List", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Already Added in Compare List", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
    // Delete from Compare
    delCompare: (state, action) => {
      let { id } = action.payload;
      let arr = state.compare.filter((item) => item.id !== parseInt(id));
      if (arr.length > 0) {
        saveLocalStorage(dataLocalStorage.compare, arr);
      } else {
        saveLocalStorage(dataLocalStorage.compare, []);
      }
      state.compare = arr;
    },
    addFavorite: (state, action) => {
      let { itemData } = action.payload;
      // let arr = [...state.favorites];

      // itemData.forEach((newItem) => {
      //   let item = arr.find((i) => parseInt(i.id) === parseInt(newItem.id));
      //   if (!item) {
      //     arr.push(newItem);
      //   }
      // });

      state.favorites = itemData;
      saveLocalStorage(dataLocalStorage.favorite, state.favorites);
    },
    // Add&Remove Favorite / Wishlist
    manageFavorite: (state, action) => {
      let { itemData, dispatch } = action.payload;
      let item = state.favorites.find((i) => i.id === parseInt(itemData.id));
      if (item === undefined) {
        state.favorites.push(itemData);
        toast.success(
          `You have successfully added ${itemData.name} to your favorites.`,
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        let arr = state.favorites.filter((item) => item.id !== itemData.id);
        state.favorites = arr;
        toast.success(
          `You have successfully removed ${itemData.name} from your favorites.`,
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
      saveLocalStorage(dataLocalStorage.favorite, state.favorites);
      ManageFavorite({
        product_id: itemData.id,
        is_add: item === undefined ? 1 : 0,
        callback: () => {},
      });
    },
    // not use
    addCarts: (state, action) => {
      state.carts = action.payload.newData;
    },
    // not use
    clearCart: (state) => {
      removeLocalStorage(dataLocalStorage.card);
      state.carts = [];
    },
    // not use
    removeCart: (state, action) => {
      let { element } = action.payload;
      let arr = state.carts.filter(
        (item) =>
          !(element?.id == item?.id && element?.size?.name == item?.size?.name)
      );
      state.carts = arr;
    },
    // not use
    updateCart: (state, action) => {
      let { val, element } = action.payload;
      state.carts.forEach((item) => {
        if (
          item.id == parseInt(element.id) &&
          element?.size?.name == item?.size?.name
        ) {
          item.quantity = val;
        }
      });
    },
    // not use
    getProductById: (state, action) => {
      let { id } = action.payload;
      let arr = state.products.find((item) => item.id === parseInt(id));
      state.single = arr;
    },
    // not use
    addToCart: (state, action) => {
      let { id } = action.payload;

      // Check existance
      let item = state.carts.find((i) => i.id === parseInt(id));
      if (item === undefined) {
        // Get Product
        let arr = state.products.find((item) => item.id === parseInt(id));
        arr.quantity = 1;
        state.carts.push(arr);
        toast.success("Successfully added to your Cart", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("This product is already added in your Cart", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  },
});

const productsReducer = productsSlice.reducer;
export default productsReducer;
