import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RouterName } from "../../RouterName";

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-3">
        <div className="dashboard_tab_button">
          <ul role="tablist" className="nav flex-column dashboard-list">
            <li>
              <Link
                to={RouterName.my_account.order}
                className={
                  location.pathname === RouterName.my_account.order
                    ? "active"
                    : null
                }
              >
                <i className="fa fa-cart-arrow-down"></i>Orders
              </Link>
            </li>
            <li>
              <Link
                to={RouterName.my_account.address}
                className={
                  location.pathname === RouterName.my_account.address
                    ? "active"
                    : null
                }
              >
                <i className="fa fa-map-marker"></i>Addresses
              </Link>
            </li>
            <li>
              <Link
                to={RouterName.my_account.my_accountDetails}
                className={
                  location.pathname === RouterName.my_account.my_accountDetails
                    ? "active"
                    : null
                }
              >
                <i className="fa fa-user"></i>Account details
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
