import { toast } from "react-hot-toast";
import { Autologin } from "../app/slices/User/actionsUser";

import { RouterName } from "../RouterName";

const fullUrl = window.location.href;
const parsedUrl = new URL(fullUrl);
export const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${
  parsedUrl.port ? `:${parsedUrl.port}` : ""
}`;

export function cleanSizes(sizesList) {
  const sizeOrder = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"];
  const numericSizes = Array.from({ length: 50 }, (_, i) => (i + 1).toString());
  const allSizes = [...sizeOrder, ...numericSizes];
  const sizeMap = new Map();

  function removeColor(sizeName) {
    // Remove color information
    return sizeName
      .replace(/^[\w-]+\s+(?=(?:\d+|XS|S|M|L|XL|2XL|3XL|4XL|5XL)$)/i, "") // Remove prefix
      .replace(/(?<=^(?:\d+|XS|S|M|L|XL|2XL|3XL|4XL|5XL))\s+[\w-]+$/i, ""); // Remove suffix
  }

  sizesList.forEach((item) => {
    let size = item.name.toUpperCase().trim();

    // Remove color information
    size = removeColor(size);

    // Standardize size names
    if (size === "EXTRA SMALL") size = "XS";
    else if (size === "SMALL") size = "S";
    else if (size === "MEDIUM") size = "M";
    else if (size === "LARGE") size = "L";
    else if (size === "EXTRA LARGE") size = "XL";
    else if (size === "2X LARGE") size = "2XL";
    else if (size === "3X LARGE") size = "3XL";
    else if (size === "4X LARGE") size = "4XL";
    else if (size === "5X LARGE") size = "5XL";

    // Check if it's a valid size (including numeric sizes)
    if (allSizes.includes(size)) {
      if (!sizeMap.has(size)) {
        sizeMap.set(size, { id: item.id, name: size });
      }
    }
  });

  // Convert map to array and sort by size order
  return Array.from(sizeMap.values()).sort((a, b) => {
    const aIndex = allSizes.indexOf(a.name);
    const bIndex = allSizes.indexOf(b.name);
    return aIndex - bIndex;
  });
}

export const keywords = [
  "sports outlet",
  "discount sports gear",
  "sports apparel",
  "outlet sportswear",
  "cheap sports equipment",
  "sports shoes",
  "sports accessories",
  "Lebanon sports outlet",
  "shoes",
  "Men's T-Shirts",
  "Women's Leggings",
  "Women Crop Tops",
  "Sports Bra",
  "Men Swim Shorts",
  "Men's Shorts",
  "Women's Shorts",
  "Women's Tops & T-Shirts",
  "Men Tanks",
  "Men's Pants",
  "Men's Jackets",
  "Men's Hoodies",
  "Women's Jacket & Hoodies",
  "Men's Shoes",
  "Women's Shoes",
  "Accessories",
  "Sports Handbags & Backpacks",
  "Men's Tracksuits",
  "Socks & Underwears",
  "Tactical Boots",
  "Football Shoes",
  "Basketball Shoes",
  "Women's Tracksuits",
  "Women's Pants",
  "Men's Sweatshirts",
  "Sliders",
  "CAPS",
  "BALLS",
  "Men's Legging",
  "online shopping",
  "lebanon sports",
  "lebanon",
];

export const StripHtmlTags = (html) => {
  if (html) {
    let data = html.replace(/<[^>]*>/g, "");
    let newData = data.replace("&amp;", "");
    let newDatanew = newData.replace("\r\n", " ");
    return newDatanew;
  } else {
    return "";
  }
};
export const CheckDataIfExit = (data) => {
  if ((data != null && data != undefined) || data == 0) {
    return true;
  } else {
    return false;
  }
};
export const listOrderStatus = [
  { name: "Pending", id: "pending" },
  { name: "Accepted", id: "accepted" },
  { name: "On the way", id: "on the way" },
  { name: "delivered", id: "delivered" },
  { name: "Canceled", id: "canceled" },
];

export let cardItem = [];

export let isDevelopmentMode =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
    ? true
    : false;

export const getLinkPagination = ({
  page = "",
  rowsPerPage = "",
  orderBy = "",
  typeOrder = "",
  search = "",
  fieldsSearch = "",
}) => {
  let quary;
  if (page != null && page != "null") {
    quary = `offset=${page - 1}`;
  }
  if (rowsPerPage != null && rowsPerPage != "null") {
    quary = quary + `&page_size=${rowsPerPage}`;
  }
  if (orderBy && orderBy != "null") {
    quary = quary + `&sortField=${orderBy}`;
  }
  if (typeOrder && typeOrder != "null") {
    quary = quary + `&order=${typeOrder}`;
  }
  if (search && search != "null") {
    quary = quary + `&search=${search}`;
  }
  if (fieldsSearch && fieldsSearch != "null" && isValidArray(fieldsSearch)) {
    quary = quary + `&fieldsSearch=${JSON.stringify(fieldsSearch)}`;
  }
  return quary;
};

export const dataLocalStorage = {
  userinfo: "user",
  loading_check_user: "loading_check_user",
  card: "card",
  compare: "compare",
  favorite: "favorite",
  store_settings: "store_settings",
  cookie: "cookie",
  stopCookie: "stopCookie",
  promoCenter: "promoCenter",
  promoStatus: "promoStatus",
  stopPromo: "stopPromo",
};
export const columntype = {
  text: "text",
  image: "image",
  status: "status",
  date: "date",
  time: "time",
  action: "action",
  switch: "switch",
  checkbox: "checkbox",
};

export const printData = ({ name, data, type }) => {
  // if (isDevelopmentMode) {
  //   if (type == "error") {
  //     console.error({ [name]: data });
  //   } else {
  //     console.log({ [name]: data });
  //   }
  // } else {
  //   return;
  // }
};

export const saveLocalStorage = (key, data) => {
  localStorage.setItem([key], JSON.stringify(data));
};

export const getLocalStorage = (key, isArray = false, isObject = false) => {
  let data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    if (isArray) {
      return [];
    } else if (isObject) {
      return {};
    } else {
      return "";
    }
  }
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const removeAllLocalStorage = () => {
  localStorage.clear();
};

export const CheckItemIfExit = (id, array) => {
  let isExit = array?.some((element) => element?.id == id);
  return isExit;
};

export const removeItemFromArray = (id, array) => {
  let newArray = array?.filter((item) => item?.id !== id);
  return newArray;
};
export const getItemFromArray = (id, array) => {
  let newArray = array?.filter((item) => item?.id == id);
  return newArray[0];
};
export const AddItemToArray = (item, array) => {
  return [...array, item];
};
export const editItemInArray = (editedItem, array, AlldataItem) => {
  const newArray = array.map((item) => {
    if (
      item?.id === editedItem?.id &&
      AlldataItem?.size?.name == item?.size?.name
    ) {
      return { ...item, ...editedItem }; // Replace the item with the edited item
    }
    return item;
  });
  return newArray;
};

export function isEquivalent(a, b) {
  try {
    return JSON.stringify(a) === JSON.stringify(b);
  } catch (e) {
    return false;
  }
}
export function isValidArray(array) {
  return array && Array.isArray(array) && array?.length > 0;
}

export function validURL(str) {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}

export function isNumber(data) {
  if (isNaN(data)) {
    return false;
  } else {
    return true;
  }
}
export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
const successMessageRemove = ({ name, size }) => {
  toast.success(`Successfully removed ${name} (${size}) from your cart.`, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

const successMessageAdd = ({ name, size }) => {
  toast.success(`Successfully added ${name} (${size}) to your cart.`, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const CartManagement = async ({
  Dataitem,
  quantity,
  size,
  add = true,
}) => {
  if (size) {
    let item = { ...Dataitem };
    item.quantity = quantity;
    item.size = size;
    let allCard = getLocalStorage(dataLocalStorage.card);
    if (allCard?.length > 0) {
      let oldItem = allCard?.filter(
        (element) =>
          element?.id == item.id && element?.size?.name == item?.size?.name
      );
      if (oldItem?.length > 0) {
        if (oldItem[0]?.quantity == 1 && !add) {
          // remove item when have quantity 1
          let updatedArray = await allCard?.filter(
            (element) =>
              !(
                element?.id == item?.id &&
                element?.size?.name == item?.size?.name
              )
          );
          cardItem = updatedArray;
          saveLocalStorage(dataLocalStorage.card, updatedArray);
          successMessageRemove({
            name: item.name,
            size: item?.size?.name,
          });
          return updatedArray;
        } else {
          if (
            item?.quantity + oldItem[0]?.quantity <= item?.size?.quantity ||
            !add
          ) {
            /**
             if(add){
               increase quantity item
             }else{
               decrease quantity item
             }
              */
            let updatedArray = await editItemInArray(
              {
                id: oldItem[0]?.id,
                quantity: add
                  ? parseInt(oldItem[0]?.quantity) + parseInt(item?.quantity)
                  : parseInt(oldItem[0]?.quantity) - parseInt(item?.quantity),
              },
              allCard,
              item
            );
            cardItem = updatedArray;
            saveLocalStorage(dataLocalStorage.card, updatedArray);
            if (add) {
              successMessageAdd({ name: item.name, size: item?.size?.name });
            } else {
              successMessageRemove({
                name: item.name,
                size: item?.size?.name,
              });
            }
            return updatedArray;
          } else {
            // i want increase quantity item but i don't have it in stock
            toast.error(
              `Sorry, you can't add this item. We have only ${item?.size?.quantity} (${item?.size?.name}) in stock.`,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            return allCard;
          }
        }
      } else {
        // if card have item and i want add another item
        let newArray = AddItemToArray(item, allCard);
        cardItem = newArray;
        saveLocalStorage(dataLocalStorage.card, newArray);
        successMessageAdd({ name: item.name, size: item?.size?.name });
        return newArray;
      }
    } else {
      // if card no have item and add new array and new item to cart
      let array = [];
      let newArray = AddItemToArray(item, array);
      cardItem = newArray;
      saveLocalStorage(dataLocalStorage.card, newArray);
      successMessageAdd({ name: item.name, size: item?.size?.name });
      return newArray;
    }
  }
};

export const Checkuser = ({ dispatch, navigate }) => {
  saveLocalStorage(dataLocalStorage.loading_check_user, true);
  const data = getLocalStorage(dataLocalStorage.userinfo);
  dispatch({
    type: "user/setUserInfo",
    payload: data,
  });

  if (data?.token) {
    dispatch(
      Autologin({
        callback: () => {
          saveLocalStorage(dataLocalStorage.loading_check_user, false);
        },
      })
    );
    if (
      window.location.pathname == "/login" ||
      window.location.pathname == "/forgot-password"
    ) {
      navigate.push(RouterName.home.home);
    }
  } else {
    saveLocalStorage(dataLocalStorage.loading_check_user, false);
  }
};
