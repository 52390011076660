import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Logout } from "../../../app/slices/User/actionsUser";
import { RouterName } from "../../../RouterName";

const TopHeader = () => {
  let dispatch = useDispatch();
  const history = useHistory();
  let user_id = useSelector((state) => state.user?.user_info?.id);
  let user = useSelector((state) => state.user.user_info);

  return (
    <>
      <section id="top_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="top_header_left">
                <p>Special collection already available.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="top_header_right">
                {!user_id ? (
                  <ul className="right_list_fix">
                    <li>
                      <Link to={RouterName.home.compare}>
                        <i className="fa fa-refresh"></i> Compare
                      </Link>
                    </li>
                    <li>
                      <Link to={RouterName.auth.login}>
                        <i className="fa fa-user"></i> Login
                      </Link>
                    </li>
                    <li>
                      <Link to={RouterName.auth.register}>
                        <i className="fa fa-lock"></i> Register
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <ul className="right_list_fix">
                    <li className="after_login">
                      {`${user.first_name ?? ""} ${user.last_name ?? ""}`}
                      <i className="fa fa-angle-down"></i>
                      <ul className="custom_dropdown">
                        <li>
                          <Link to={RouterName.my_account.my_accountDetails}>
                            <i className="fa fa-tachometer"></i> My account
                          </Link>
                        </li>
                        <li>
                          <Link to="/my-account/customer-order">
                            <i className="fa fa-cubes"></i> My Orders
                          </Link>
                        </li>
                        <li>
                          <Link to="/compare">
                            <i className="fa fa-refresh"></i> Compare
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#!"
                            onClick={() => {
                              dispatch(Logout({ navigate: history }));
                            }}
                          >
                            <i className="fa fa-sign-out"></i> Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopHeader;
