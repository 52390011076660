import React from "react";
import Logo from "../../logo";
const Loader = () => {
  return (
    <>
      <div className="loader_wrapper">
        <Logo customStyle={{ width: "200px" }} />
      </div>
    </>
  );
};

export default Loader;
