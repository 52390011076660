import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload"; // Import LazyLoad
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import UrlApi from "../../config/Url";
import "./style.css";

const ImageWithLoadingError = ({
  src,
  alt = "image",
  className,
  style,
  isLocal = false,
  withOrginalClass = true,
  withLoading = true,
  onClick = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
      setIsLoading(false);
    };
    img.onerror = () => setError(true);
    img.src = isLocal ? src : UrlApi.baseUrlImage + src;
  }, [src]);

  if (isLoading && withLoading) {
    return (
      <div
        className={`${className} image-loading ${
          withOrginalClass ? "imageProduct" : ""
        }`}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: 50 }} />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={`${className} image-error ${
          withOrginalClass ? "imageProduct" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{ fontSize: 50 }}
        />
      </div>
    );
  }

  return (
    <LazyLoad height={imageSize.height} offset={100} once>
      <img
        onClick={onClick}
        src={isLocal ? src : UrlApi.baseUrlImage + src}
        alt={alt}
        className={`${className} ${withOrginalClass ? "imageProduct" : ""}`}
        style={style}
        loading="lazy"
      />
    </LazyLoad>
  );
};

export default ImageWithLoadingError;
