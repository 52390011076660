import logo from "../../assets/img/logo.png";

const Logo = ({ customStyle, className }) => {
  return (
    <img
      src={logo}
      className={className}
      style={customStyle ? customStyle : { height: 100 }}
      alt="Logo"
    />
  );
};

export default Logo;
