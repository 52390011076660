import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Button } from "react-bootstrap";
import Colors from "../../helper/Colors";
import ImageWithLoadingError from "../image/ImageWithLoadingError";

const DropdownWithSearch = ({
  options,
  selected_id,
  handleOptionSelect,
  customeStyle,
  className,
  itemsPerPage = 5, // Default items per page
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selected, setSelected] = useState({ name: "" });
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
    setCurrentPage(1); // Reset to first page on search
  };

  useEffect(() => {
    const optionSelected = options.filter((option) => option.id == selected_id);
    if (optionSelected.length > 0) {
      setSelected(optionSelected[0]);
    } else {
      setSelected(options[0]);
    }
  }, [selected_id]);

  const totalPages = Math.ceil(filteredOptions.length / itemsPerPage);
  const paginatedOptions = filteredOptions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        style={
          customeStyle ?? {
            width: "100%",
            background: Colors.primary,
            borderColor: Colors.background,
            borderRadius: 10,
          }
        }
        id="dropdown-basic"
        className={className}
      >
        {selected?.name}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "100%" }}>
        <FormControl
          autoFocus
          placeholder="Search..."
          onChange={handleSearchChange}
          value={searchTerm}
        />
        <Dropdown.Divider />
        {paginatedOptions?.map((option, index) => (
          <Dropdown.Item onClick={() => handleOptionSelect(option)} key={index}>
            <div style={{ display: "table-row" }}>
              {option.image && (
                <ImageWithLoadingError
                  src={option.image}
                  style={{ width: 40, height: 40, marginRight: 10 }}
                />
              )}
              {option.name}
            </div>
          </Dropdown.Item>
        ))}
        {totalPages > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Button
              disabled={currentPage === 1}
              style={{
                borderColor: " #771e24",
                color: " #771e24",
                background: "white",
              }}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Back
            </Button>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
            <Button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              style={{
                borderColor: " #771e24",
                color: " #771e24",
                background: "white",
              }}
            >
              Next
            </Button>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

// Example usage

export default DropdownWithSearch;
