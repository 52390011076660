import { Helmet } from "react-helmet";
import UrlApi from "../../../config/Url";
import Logo from "../../../assets/img/logo.png";
const SeoDetails = ({
  name,
  description,
  imageUrl,
  url,
  children,
  keyword,
  siteName,
  withLogo = true,
}) => {
  return (
    <>
      <Helmet>
        {name ? <title>{name}</title> : <title>ALL★STAR SPORT</title>}
        {withLogo && <link rel="icon" href={Logo} />}
        {name ? (
          <meta property="og:title" content={name} />
        ) : (
          <meta property="og:title" content={"ALL★STAR SPORT"} />
        )}
        {description ? (
          <meta property="og:description" content={description} />
        ) : (
          <meta
            property="og:description"
            content="ALL★STAR SPORT is the one stop shop for all your sporting goods and equipment. One of the leading Sports Retailer & Wholesaler in Lebanon, ALL★STAR SPORT offers you the highest quality in the market for the lowest price"
          />
        )}
        {description ? (
          <meta name="description" content={description} />
        ) : (
          <meta
            name="description"
            content="ALL★STAR SPORT is the one stop shop for all your sporting goods and equipment. One of the leading Sports Retailer & Wholesaler in Lebanon, ALL★STAR SPORT offers you the highest quality in the market for the lowest price"
          />
        )}
        {description ? (
          <meta property="description" content={description} />
        ) : (
          <meta
            property="description"
            content="ALL★STAR SPORT is the one stop shop for all your sporting goods and equipment. One of the leading Sports Retailer & Wholesaler in Lebanon, ALL★STAR SPORT offers you the highest quality in the market for the lowest price"
          />
        )}
        {description ? (
          <meta name="twitter:description" content={description} />
        ) : (
          <meta
            name="twitter:description"
            content="ALL★STAR SPORT is the one stop shop for all your sporting goods and equipment. One of the leading Sports Retailer & Wholesaler in Lebanon, ALL★STAR SPORT offers you the highest quality in the market for the lowest price"
          />
        )}
        {imageUrl && (
          <meta property="og:image" content={UrlApi.baseUrlImage + imageUrl} />
        )}
        {imageUrl && (
          <meta property="image" content={UrlApi.baseUrlImage + imageUrl} />
        )}
        {imageUrl && (
          <meta name="og:image" content={UrlApi.baseUrlImage + imageUrl} />
        )}
        {imageUrl && (
          <meta name="image" content={UrlApi.baseUrlImage + imageUrl} />
        )}
        {url && <meta property="og:url" content={url} />}
        {url && <meta name="og:url" content={url} />}
        <meta property="og:type" content="website" />

        {url && <link rel="canonical" href={url} />}
        <meta name="twitter:card" content="app" />
        {name ? (
          <meta name="twitter:title" content={name} />
        ) : (
          <meta name="twitter:title" content={"ALL★STAR SPORT"} />
        )}

        {imageUrl && (
          <meta name="twitter:image" content={UrlApi.baseUrlImage + imageUrl} />
        )}
        {siteName && <meta property="og:site_name" content={siteName} />}
        <meta property="og:locale" content="ar_LB" />
        {keyword && <meta name="keywords" content={keyword} />}
        {siteName && <meta name="author" content={siteName} />}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {children}
    </>
  );
};
export default SeoDetails;
