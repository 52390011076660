import React from "react";
import { Link } from "react-router-dom";
import { RouterName } from "../../../RouterName";
import ImageBg from "../../../assets/img/common/common_bg.png";
import UrlApi from "../../../config/Url";

const Banner = ({ title, image }) => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(${
            image ? UrlApi.baseUrlImage + image : ImageBg
          })`,
          padding: "90px 0",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container ">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="common_banner_text"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",

                  display: "flex",
                }}
              >
                <div
                  style={{
                    background: "rgba(0, 0, 0, 0.526)",
                    width: `${title.length * 50}px`,
                    padding: 10,
                    borderRadius: 30,
                  }}
                >
                  <h2>{title}</h2>
                  <ul>
                    <li>
                      <Link to={RouterName.home.home}>Home</Link>
                    </li>
                    <li className="slash">/</li>
                    <li className="active">{title}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
