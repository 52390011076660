import { createSlice } from "@reduxjs/toolkit";
import {
  dataLocalStorage,
  getLocalStorage,
} from "../../../helper/publicFunction";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    user_info: getLocalStorage(dataLocalStorage.userinfo, false, true),
    address: [],
    orders: [],
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.user_info = action?.payload;
    },
    removeUserInfo: (state, action) => {
      state.user_info = {};
    },
    setDataPrivileges: (state, action) => {
      state.dataPrivileges = action.payload;
    },
  },
});

const UserReducer = UserSlice.reducer;

export default UserReducer;
