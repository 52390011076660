import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddAddress, GetAddress } from "./actions";
import AddressComponent from "./AddressComponent";
import { FaPlus } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../Common/Footer";
import Layout from "./Layout";
import Banner from "../Common/Banner";
import Header from "../Common/Header";
import DropdownWithSearch from "../DropdownWithSearch";
import { getCiteis } from "../../app/slices/User/actionsUser";

const AddressManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const AddressData = location.state;
  const [citys, setcitys] = useState([]);
  const [selectedCity, setselectedCity] = useState(AddressData?.city_id ?? "");
  const [addressName, setAddressName] = useState(AddressData?.name ?? "");
  const [street, setStreet] = useState(AddressData?.street ?? "");
  const [building, setBuilding] = useState(AddressData?.buildingName ?? "");
  const [floor, setFloor] = useState(AddressData?.floor ?? "");

  useEffect(() => {
    dispatch(
      getCiteis({
        callback: (data) => {
          setcitys(data);
          if (!AddressData) {
            setselectedCity(data[0].id);
          }
        },
      })
    );
  }, []);
  let appsettings = useSelector((state) => state.settings.appsettings);

  return (
    <>
      <Header />
      <Banner
        title="Customer Dashboard"
        image={appsettings?.image_customer_dashboard}
      />
      <Layout>
        <div className="myaccount-content">
          <h4 className="title">
            {AddressData ? "Edit Address" : "New Address"}
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(
                AddAddress({
                  id: AddressData?.id ?? null,
                  name: addressName,
                  street: street,
                  buildingName: building,
                  floor: floor,
                  city_id: selectedCity,
                  callback: (data) => {
                    if (data) {
                      history.goBack();
                    }
                  },
                })
              );
            }}
          >
            <div className="default-form-box">
              <label>
                City<span className="text-danger">*</span>
              </label>
              {citys.length > 0 && (
                <DropdownWithSearch
                  customeStyle={{
                    background: "transparent",
                    color: "black",
                    borderColor: "rgba(128, 128, 128, 0.453)",
                    height: 50,
                  }}
                  className="form-control"
                  options={citys}
                  selected_id={selectedCity}
                  handleOptionSelect={(res) => {
                    setselectedCity(res.id);
                  }}
                />
              )}
            </div>
            <div className="default-form-box">
              <label>
                Address name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={addressName}
                onChange={(e) => setAddressName(e.currentTarget.value)}
                required
              />
            </div>
            <div className="default-form-box">
              <label>
                Street<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={street}
                onChange={(e) => setStreet(e.currentTarget.value)}
                required
              />
            </div>
            <div className="default-form-box">
              <label>
                building<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={building}
                onChange={(e) => setBuilding(e.currentTarget.value)}
                required
              />
            </div>
            <div className="default-form-box">
              <label>
                Floor<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={floor}
                onChange={(e) => setFloor(e.currentTarget.value)}
                required
              />
            </div>

            <div style={{ marginTop: 10, width: "100%" }}>
              <button
                className="theme-btn-one btn-black-overlay btn_md"
                type="submit"
                style={{ width: "100%" }}
              >
                {AddressData ? "Save" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </Layout>
      <Footer />
    </>
  );
};
export default AddressManagement;
