import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./slices/products";
import settingsReducer from "./slices/settings";
import userReducer from "./slices/User/reducerUser";
import SettingsReducer from "./slices/Settings/reducerSettings";
export const store = configureStore({
  reducer: {
    user: userReducer,
    products: productsReducer,
    settings: settingsReducer,
    reducerSettings: SettingsReducer,
  },
});
