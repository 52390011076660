import { PostFunction } from "../../../config/axios_connection";
import UrlApi from "../../../config/Url";
import {
  dataLocalStorage,
  getLocalStorage,
  removeAllLocalStorage,
  saveLocalStorage,
} from "../../../helper/publicFunction";
import { setLoading } from "../Settings/reducerSettings";
import { RouterName } from "../../../RouterName";
import Swal from "sweetalert2";
import { GetFavorites } from "../../../page/home/actions";

export const getCiteis =
  ({ callback }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const response = await PostFunction({
        data: { is_active: 1 },
        Route: UrlApi.city.get,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      dispatch(setLoading(false));
      callback(response.data);
    } catch (err) {
      dispatch(setLoading(false));
      callback(false);
    }
  };

export const login =
  ({ email, password, expire, callback }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const fullUrl = window.location.href;
      const parsedUrl = new URL(fullUrl);
      const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}:${parsedUrl.port}`;
      const data = {
        email,
        password,
        expire: 90,
        currentDomain: baseUrl,
      };
      const response = await PostFunction({
        data,
        Route: UrlApi.auth.login,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      saveLocalStorage(dataLocalStorage.userinfo, response?.data);

      dispatch({
        type: "user/setUserInfo",
        payload: response?.data,
      });
      dispatch(setLoading(false));
      callback(response);
    } catch (err) {
      dispatch(setLoading(false));
      callback(false);
    }
  };

export const Autologin =
  ({ callback, navigate }) =>
  async (dispatch, getState) => {
    try {
      dispatch(getSettings());
      const dataUser = await getLocalStorage(dataLocalStorage.userinfo);
      if (dataUser) {
        const response = await PostFunction({
          data: {},
          Route: UrlApi.auth.autologin,
          showalert: false,
          state: getState,
          isMultipart: false,
          dispatch: dispatch,
        });
        saveLocalStorage(dataLocalStorage.userinfo, response?.data);

        dispatch({
          type: "user/setUserInfo",
          payload: response?.data,
        });
        dispatch(GetFavorites());
        dispatch({
          type: "user/setDataPrivileges",
          payload: response?.dataPrivileges,
        });
      }
      callback();
    } catch (err) {
      dispatch(Logout({ navigate, withMessage: false }));
    }
  };

export const signup =
  ({ values, callback, withMessage = true }) =>
  async (dispatch, getState) => {
    try {
      let AllValue = values;
      const fullUrl = window.location.href;
      const parsedUrl = new URL(fullUrl);
      const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}:${parsedUrl.port}`;
      AllValue.currentDomain = baseUrl;
      dispatch(setLoading(true));
      const response = await PostFunction({
        data: values,
        Route: UrlApi.auth.signup,
        showalert: withMessage,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      dispatch(setLoading(false));
      callback(response);
    } catch (err) {
      dispatch(setLoading(false));
      callback(false);
    }
  };

export const Logout =
  ({ navigate = null, withMessage = true, route = "" }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const dataUser = await getLocalStorage(
        dataLocalStorage.userinfo,
        false,
        true
      );
      let response;
      if (dataUser?.token) {
        response = await PostFunction({
          data: {},
          Route: UrlApi.auth.logout,
          showalert: true,
          state: getState,
          isMultipart: false,
          dispatch: dispatch,
        });
      } else {
        response = true;
      }
      if (response) {
        removeAllLocalStorage();
        dispatch({ type: "user/removeUserInfo", payload: {} });
        dispatch(setLoading(false));
        if (withMessage) {
          await Swal.fire({
            icon: "success",
            title: "Logout Sucessfull",
            text: "Thank You",
          });
        }
        if (navigate != null) {
          navigate.push(route ?? "/login");
          window.location.reload(false);
        } else {
          window.location.reload(false);
        }
      } else {
        dispatch(setLoading(false));
      }
    } catch (err) {
      dispatch(setLoading(false));
    }
  };
export const LogoutLocal = async () => {
  try {
    removeAllLocalStorage();
  } catch (err) {}
};

export const ActivateAccountFunction =
  ({ token, callback, history }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: {
          token,
        },
        Route: UrlApi.auth.activateAccount,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      if (response) {
        callback(response);
      } else {
        history.push(RouterName.auth.login);
        callback(false);
      }
    } catch (err) {
      history.push(RouterName.auth.login);
    }
  };

export const ForgotPasswordFunction =
  ({ email, callback }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const fullUrl = window.location.href;
      const parsedUrl = new URL(fullUrl);
      const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}:${parsedUrl.port}`;
      const response = await PostFunction({
        data: {
          email,
          currentDomain: baseUrl,
        },
        Route: UrlApi.auth.forgotPassword,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      if (response) {
        dispatch(setLoading(false));
        callback(response);
      } else {
        dispatch(setLoading(false));
        callback(false);
      }
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const ResetPasswordFunction =
  ({ token, newPassword, callback }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const response = await PostFunction({
        data: {
          newPassword,
          token,
        },
        Route: UrlApi.auth.resetPassword,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      if (response) {
        dispatch(setLoading(false));
        callback(response);
      } else {
        dispatch(setLoading(false));
        callback(false);
      }
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const getSettings = () => async (dispatch, getState) => {
  try {
    const response = await PostFunction({
      data: {},
      Route: UrlApi.settings.get,
      showalert: false,
      state: getState,
      isMultipart: false,
      dispatch: dispatch,
    });
    dispatch({
      type: "settings/setSettings",
      payload: { settings: response?.data },
    });
    saveLocalStorage(dataLocalStorage.store_settings, response?.data);
  } catch (err) {}
};
