import React, { useEffect, useRef } from "react";
import { Switch, Route, BrowserRouter, StaticRouter } from "react-router-dom";
import loadable from "./component/Common/loader/loadable";
import Loading from "./component/Common/loader";
import { RouterName } from "./RouterName";
import { Autologin } from "./app/slices/User/actionsUser";
import { useDispatch, useSelector } from "react-redux";
import AddressManagement from "./component/MyAccountDashboard/AddressManagment";

// used import
const HomePage = loadable(() => import("./page/home"), {
  fallback: <Loading />,
});
const AllItems = loadable(() => import("./page/shop/AllItems"), {
  fallback: <Loading />,
});
const ProductDetails = loadable(() => import("./page/product/index"), {
  fallback: <Loading />,
});
const Login = loadable(() => import("./page/login"), {
  fallback: <Loading />,
});
const ForgotPassword = loadable(() => import("./page/forgotPassword"), {
  fallback: <Loading />,
});
const ResetPassword = loadable(() => import("./page/resetPassword"), {
  fallback: <Loading />,
});
const Register = loadable(() => import("./page/register"), {
  fallback: <Loading />,
});
const ActivateAccount = loadable(() => import("./page/activate-account"), {
  fallback: <Loading />,
});

// All Page Lazy Import
const Furniture = loadable(() => import("./page/furniture"), {
  fallback: <Loading />,
});
const Electronics = loadable(() => import("./page/electronics"), {
  fallback: <Loading />,
});
const Grocery = loadable(() => import("./page/grocery"), {
  fallback: <Loading />,
});
const Pharmacy = loadable(() => import("./page/pharmacy"), {
  fallback: <Loading />,
});
const Jewllary = loadable(() => import("./page/jewllary"), {
  fallback: <Loading />,
});
const BabyToys = loadable(() => import("./page/baby-toys"), {
  fallback: <Loading />,
});
const CategoryGrid = loadable(() => import("./page/shop"), {
  fallback: <Loading />,
});
const ShopTwo = loadable(() => import("./page/shop/shop-two"), {
  fallback: <Loading />,
});
const ShopList = loadable(() => import("./page/shop/shop-list"), {
  fallback: <Loading />,
});

const ShopRightSideBar = loadable(
  () => import("./page/shop/shop-right-sidebar"),
  { fallback: <Loading /> }
);

const ProductDetailsTwos = loadable(
  () => import("./page/product/product-details-two"),
  { fallback: <Loading /> }
);
const Cart = loadable(() => import("./page/cart/index"), {
  fallback: <Loading />,
});
const CartTwo = loadable(() => import("./page/cart/cart-two"), {
  fallback: <Loading />,
});
const CartThree = loadable(() => import("./page/cart/cart-three"), {
  fallback: <Loading />,
});
const EmptyCarts = loadable(() => import("./page/cart/empty-cart"), {
  fallback: <Loading />,
});
const CheckoutOne = loadable(() => import("./page/checkout/index"), {
  fallback: <Loading />,
});
const CheckoutTwos = loadable(() => import("./page/checkout/checkout-two"), {
  fallback: <Loading />,
});
const WishLists = loadable(() => import("./page/shop/wishList"), {
  fallback: <Loading />,
});
const Compares = loadable(() => import("./page/shop/compares"), {
  fallback: <Loading />,
});
const About = loadable(() => import("./page/about"), {
  fallback: <Loading />,
});
const OrderComplete = loadable(() => import("./page/order/order-complete"), {
  fallback: <Loading />,
});
const OrderTracking = loadable(() => import("./page/order/order-tracking"), {
  fallback: <Loading />,
});
const ProductHover = loadable(() => import("./page/product/product-hover"), {
  fallback: <Loading />,
});
const OrderSuccesses = loadable(() => import("./page/order/order-success"), {
  fallback: <Loading />,
});
const EmailTemplateOnes = loadable(() => import("./page/email/index"), {
  fallback: <Loading />,
});
const EmailTemplateTwos = loadable(
  () => import("./page/email/email-template-two"),
  { fallback: <Loading /> }
);
const EmailTemplateThrees = loadable(
  () => import("./page/email/email-template-three"),
  { fallback: <Loading /> }
);
const InvoiceOne = loadable(() => import("./page/invoice/index"), {
  fallback: <Loading />,
});
const InvoiceTwo = loadable(() => import("./page/invoice/invoice-two"), {
  fallback: <Loading />,
});
const LookBooks = loadable(() => import("./page/shop/look-book"), {
  fallback: <Loading />,
});
const BlogGridThrees = loadable(() => import("./page/blog/blog-grid-two"), {
  fallback: <Loading />,
});
const BlogGridTwos = loadable(() => import("./page/blog/"), {
  fallback: <Loading />,
});
const BlogListView = loadable(() => import("./page/blog/blog-list"), {
  fallback: <Loading />,
});
const BlogSingleOnes = loadable(() => import("./page/blog/blog-single-one"), {
  fallback: <Loading />,
});
const BlogSingleTwos = loadable(() => import("./page/blog/blog-single-two"), {
  fallback: <Loading />,
});
const Vendor = loadable(() => import("./page/vendor/"), {
  fallback: <Loading />,
});
const AllProducts = loadable(() => import("./page/vendor/all-product"), {
  fallback: <Loading />,
});
const AllOrders = loadable(() => import("./page/vendor/all-order"), {
  fallback: <Loading />,
});
const VendorProfile = loadable(() => import("./page/vendor/vendor-profile"), {
  fallback: <Loading />,
});
const AddProducts = loadable(() => import("./page/vendor/add-products"), {
  fallback: <Loading />,
});
const VendorSetting = loadable(() => import("./page/vendor/vendor-setting"), {
  fallback: <Loading />,
});
const MyAccounts = loadable(() => import("./page/my-account"), {
  fallback: <Loading />,
});
const CustomerOrder = loadable(
  () => import("./page/my-account/customer-order"),
  { fallback: <Loading /> }
);
const CustomerDownloads = loadable(
  () => import("./page/my-account/customer-downloads"),
  { fallback: <Loading /> }
);
const CustomerAddress = loadable(
  () => import("./page/my-account/customer-address"),
  { fallback: <Loading /> }
);
const CustomerAccountDetails = loadable(
  () => import("./page/my-account/customer-account-details"),
  { fallback: <Loading /> }
);
const AccountEdit = loadable(() => import("./page/vendor/account-edit"), {
  fallback: <Loading />,
});

const Error = loadable(() => import("./page/error"), {
  fallback: <Loading />,
});
const PrivacyPolicy = loadable(() => import("./page/privacy-policy"), {
  fallback: <Loading />,
});
const Faqs = loadable(() => import("./page/faqs"), {
  fallback: <Loading />,
});
const ComingSoon = loadable(() => import("./page/coming-soon"), {
  fallback: <Loading />,
});
const ContactOne = loadable(() => import("./page/contact"), {
  fallback: <Loading />,
});
const ContactTwo = loadable(() => import("./page/contact/contact-two"), {
  fallback: <Loading />,
});
const ScrollToTop = loadable(() => import("./component/Common/ScrollToTop"), {
  fallback: <Loading />,
});

const App = () => {
  const dispatch = useDispatch();
  const routerRef = useRef(null);
  let user_id = useSelector((state) => state.user?.user_info?.id);
  const Router = typeof window !== "undefined" ? BrowserRouter : StaticRouter;

  useEffect(() => {
    dispatch(
      Autologin({ callback: () => {}, navigate: routerRef.current.history })
    );
  }, []);
  return (
    <>
      <Router ref={routerRef}>
        <Switch>
          <Route path={RouterName.home.home} exact component={HomePage} />
          <Route path={RouterName.home.items} exact component={AllItems} />
          <Route path={RouterName.home.item} exact component={ProductDetails} />
          {!user_id && (
            <Route path={RouterName.auth.login} exact component={Login} />
          )}
          {!user_id && (
            <Route
              path={RouterName.auth.forgotPassword}
              exact
              component={ForgotPassword}
            />
          )}
          <Route
            path={RouterName.auth.resetPassword}
            exact
            component={ResetPassword}
          />
          {!user_id && (
            <Route path={RouterName.auth.register} exact component={Register} />
          )}
          <Route
            path={RouterName.auth.activateAccount}
            exact
            component={ActivateAccount}
          />

          <Route
            path={RouterName.home.category}
            exact
            component={CategoryGrid}
          />
          {user_id && (
            <Route
              path={RouterName.my_account.order}
              exact
              component={CustomerOrder}
            />
          )}
          {user_id && (
            <Route
              path={RouterName.my_account.address}
              exact
              component={CustomerAddress}
            />
          )}
          {user_id && (
            <Route
              path={RouterName.my_account.addressmanagement}
              exact
              component={AddressManagement}
            />
          )}
          {user_id && (
            <Route
              path={RouterName.my_account.my_accountDetails}
              exact
              component={CustomerAccountDetails}
            />
          )}
          {user_id && (
            <Route
              path={RouterName.my_account.edit_account}
              exact
              component={AccountEdit}
            />
          )}
          {/* {user_id && (
              <Route
                path="/my-account/customer-download"
                exact
                component={CustomerDownloads}
              />
            )} */}
          {/* {user_id && (
              <Route
                path={RouterName.my_account.my_account}
                exact
                component={MyAccounts}
              />
            )} */}
          {/* <Route path="/furniture" exact component={Furniture} />
          <Route path="/electronics" exact component={Electronics} />
          <Route path="/grocery" exact component={Grocery} />
          <Route path="/pharmacy" exact component={Pharmacy} />
          <Route path="/jewllary" exact component={Jewllary} />
          <Route path="/baby-toys" exact component={BabyToys} />
          <Route path="/shopTwo" exact component={ShopTwo} />
          <Route path="/shoplist" exact component={ShopList} />
          <Route path="/shop-right-bar" exact component={ShopRightSideBar} /> */}
          {/* <Route
            path="/product-details-two/:id"
            exact
            component={ProductDetailsTwos}
          /> */}
          <Route path={RouterName.home.cart} exact component={Cart} />
          {/* <Route path="/cartTwo" exact component={CartTwo} />
          <Route path="/cartThree" exact component={CartThree} /> */}
          {/* <Route path="/empty-cart" exact component={EmptyCarts} /> */}
          <Route
            path={RouterName.home.checkout}
            exact
            component={CheckoutOne}
          />
          {/* <Route path="/checkout-two" exact component={CheckoutTwos} /> */}
          <Route path={RouterName.home.wishlist} exact component={WishLists} />
          <Route path={RouterName.home.compare} exact component={Compares} />
          {/* <Route path="/order-complete" exact component={OrderComplete} /> */}
          {/* <Route path="/order-tracking" exact component={OrderTracking} /> */}
          {/* <Route path="/about" exact component={About} /> */}
          {/* <Route path="/product-hover" exact component={ProductHover} /> */}
          <Route
            path={RouterName.home.orderSuccess}
            exact
            component={OrderSuccesses}
          />
          {/* <Route
            path="/email-template-one"
            exact
            component={EmailTemplateOnes}
          />
          <Route
            path="/email-template-two"
            exact
            component={EmailTemplateTwos}
          />
          <Route
            path="/email-template-three"
            exact
            component={EmailTemplateThrees}
          /> */}
          {/* <Route path="/invoice-one" exact component={InvoiceOne} /> */}
          {/* <Route path="/invoice-two" exact component={InvoiceTwo} /> */}
          {/* <Route path="/lookbooks" exact component={LookBooks} /> */}
          {/* <Route path="/blog-grid-three" exact component={BlogGridThrees} />
          <Route path="/blog-grid-two" exact component={BlogGridTwos} />
          <Route path="/blog-list-view" exact component={BlogListView} />
          <Route path="/blog-single-one" exact component={BlogSingleOnes} />
          <Route path="/blog-single-two" exact component={BlogSingleTwos} /> */}
          {/* <Route path="/vendor-dashboard" exact component={Vendor} />
            <Route path="/vendor/all-product" exact component={AllProducts} />
            <Route path="/vendor/all-order" exact component={AllOrders} />
            <Route
              path="/vendor/vendor-profile"
              exact
              component={VendorProfile}
            />
            <Route path="/vendor/add-products" exact component={AddProducts} />
            <Route
              path="/vendor/vendor-setting"
              exact
              component={VendorSetting}
            /> */}
          <Route
            path={RouterName.home.privacyPolicy}
            exact
            component={PrivacyPolicy}
          />
          {/* <Route path="/faqs" exact component={Faqs} /> */}
          {/* <Route path="/coming-soon" exact component={ComingSoon} /> */}
          {/* <Route path="/contact-one" exact component={ContactOne} />
          <Route path="/contact-two" exact component={ContactTwo} /> */}
          <Route exact component={Error} />
          <Route exact path={RouterName.home.ErrorRoute} component={Error} />
        </Switch>

        <ScrollToTop />
      </Router>
    </>
  );
};

export default App;
