import React from "react";
import img from "../../../assets/img/common/modal.png";
import Modal from "react-bootstrap/Modal";
import Logo from "../../logo";
import Swal from "sweetalert2";
import { Subscription } from "../action";
import { useDispatch } from "react-redux";
import { MdEmail } from "react-icons/md"; // Import from Material Icons

const NewsletterModal = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="newsleetre_modal">
          <div className="modal-content">
            <div className="modal-body modal1 modal-bg">
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={props.start}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-7 col-md-6">
                      <div className="offer_modal_left">
                        <Logo customStyle={{ width: "25%", height: "25%" }} />
                        <h3>SUBSCRIBE TO NEWSLETTER</h3>
                        <p>
                          Subscribe to the ALL★STAR SPORT mailing list to
                          receive updates on new arrivals, special offers and
                          our promotions.
                        </p>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            dispatch(
                              Subscription({
                                email: e.target.EMAIL.value,
                                callback: (data) => {
                                  if (data) {
                                    props.stop();
                                    props.start();
                                    Swal.fire(
                                      "Success",
                                      "Thank you for your Subscribtion",
                                      "success"
                                    );
                                    document.querySelector(
                                      "input[type='email']"
                                    ).value = "";
                                  }
                                },
                              })
                            );
                          }}
                        >
                          <div className="input-group mb-3">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Your Email address"
                              required
                              name="EMAIL"
                            />
                            <div className="input-group-append">
                              <button
                                className="theme-btn-one btn-black-overlay btn_sm"
                                type="submit"
                              >
                                Subscribe
                              </button>
                            </div>
                          </div>
                          {/* <div className="check_boxed_modal">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              defaultValue="Bike"
                              onClick={props.stop}
                            />
                            <label htmlFor="vehicle1">
                              Don't show this popup again
                            </label>
                          </div> */}
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <div
                        className="offer_modal_img d-none d-md-block"
                        style={{
                          display: "block",
                          margin: "0 auto",
                          height: "100%",
                          justifyContent: "center",
                          alignContent: "center",
                          justifyItems: "center",
                          alignItems: "center",
                          justifySelf: "center",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        <MdEmail size={150} color={"#771e24"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewsletterModal;
