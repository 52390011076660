import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const SettingsSlice = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = SettingsSlice.actions;

export default SettingsSlice.reducer;
