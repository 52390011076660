import { createSlice } from "@reduxjs/toolkit";
import {
  dataLocalStorage,
  getLocalStorage,
  saveLocalStorage,
} from "../../helper/publicFunction";

// Settings Slice
const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    cookie: getLocalStorage(dataLocalStorage.cookie) ?? false,
    stopCookie: getLocalStorage(dataLocalStorage.stopCookie) ?? false,
    promoCenter: getLocalStorage(dataLocalStorage.promoCenter) ?? false,
    promoStatus: getLocalStorage(dataLocalStorage.promoStatus) ?? false,
    stopPromo: getLocalStorage(dataLocalStorage.stopPromo) ?? false,
    appsettings: getLocalStorage(dataLocalStorage.store_settings, false, true),
  },
  reducers: {
    // Promo Center Modal Stop
    stopPromo: (state) => {
      state.stopPromo = true;
      saveLocalStorage(dataLocalStorage.stopPromo, true);
    },
    // Promo Modal first appare
    promoStatus: (state) => {
      state.promoStatus = true;
      saveLocalStorage(dataLocalStorage.promoStatus, true);
    },
    // Show / hide
    promoCenter: (state) => {
      let status = !state.promoCenter;
      state.promoCenter = status;
      saveLocalStorage(dataLocalStorage.promoCenter, status);
    },
    // Bottom Cookie Stop
    stopCookie: (state) => {
      state.stopCookie = true;
      saveLocalStorage(dataLocalStorage.stopCookie, true);
    },
    // Cookie Show / hide
    cookie: (state) => {
      let status = !state.cookie;
      state.cookie = status;
      saveLocalStorage(dataLocalStorage.cookie, status);
    },
    setSettings: (state, action) => {
      state.appsettings = action.payload.settings;
    },
  },
});

const settingsReducer = settingsSlice.reducer;
export default settingsReducer;
