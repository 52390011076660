import UrlApi from "../../config/Url";
import { PostFunction } from "../../config/axios_connection";
import {
  dataLocalStorage,
  getLocalStorage,
  saveLocalStorage,
} from "../../helper/publicFunction";

export const GetAddress =
  ({ callback }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: {},
        Route: UrlApi.user.getAddress,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const AddAddress =
  ({
    callback,
    id = null,
    name = null,
    street = null,
    buildingName = null,
    floor = null,
    city_id = null,
    is_deleted = 0,
  }) =>
  async (dispatch, getState) => {
    try {
      let data = {
        is_deleted: is_deleted,
      };
      if (id) {
        data.id = id;
      }
      if (name) {
        data.name = name;
      }
      if (street) {
        data.street = street;
      }
      if (buildingName) {
        data.buildingName = buildingName;
      }
      if (floor) {
        data.floor = floor;
      }
      if (city_id) {
        data.city_id = city_id;
      }
      const response = await PostFunction({
        data: data,
        Route: UrlApi.user.manageAddress,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const EditProfile =
  ({
    firstName,
    lastName,
    email,
    phone,
    currentPassword,
    newPassword,
    callback,
  }) =>
  async (dispatch, getState) => {
    try {
      let data = {};

      if (firstName) {
        data.first_name = firstName;
      }
      if (lastName) {
        data.last_name = lastName;
      }
      if (email) {
        data.email = email;
      }
      if (phone) {
        data.phone = phone;
      }
      if (currentPassword) {
        data.currentPassword = currentPassword;
      }
      if (newPassword) {
        data.password = newPassword;
      }
      const response = await PostFunction({
        data: data,
        Route: UrlApi.user.editProfile,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      let oldData = await getLocalStorage(dataLocalStorage.userinfo);
      oldData.email = response?.data?.email;
      oldData.first_name = response?.data?.first_name;
      oldData.last_name = response?.data?.last_name;
      oldData.phone = response?.data?.phone;
      saveLocalStorage(dataLocalStorage.userinfo, oldData);
      dispatch({
        type: "user/setUserInfo",
        payload: oldData,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const GetMyOrders =
  ({ callback, order_id }) =>
  async (dispatch, getState) => {
    try {
      let data = {};
      if (order_id) {
        data.order_id = order_id;
      }
      const response = await PostFunction({
        data: data,
        Route: UrlApi.order.get,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const GetMyOrderDetails =
  ({ callback, order_id }) =>
  async (dispatch, getState) => {
    try {
      const response = await PostFunction({
        data: { order_id },
        Route: UrlApi.order.getDetails,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };
