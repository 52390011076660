import React from "react";
import "font-awesome/css/font-awesome.min.css";
// import MessengerCustomerChat from "react-messenger-customer-chat";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import Logo from "./assets/img/logo.png";

// import Custom Css
import "./assets/css/style.css";
import "./assets/css/color.css";
import "./assets/css/responsive.css";
import "./assets/css/animate.min.css";
import SeoDetails from "./component/Common/SEO";
import { baseUrl, keywords } from "./helper/publicFunction";
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Provider store={store}>
        {/* <MessengerCustomerChat
        pageId="690150731108178"
        appId="1404016446914796"
      /> */}
        {/* <SeoDetails
          name={""}
          description={
            "ALL★STAR SPORT is the one stop shop for all your sporting goods and equipment. One of the leading Sports Retailer & Wholesaler in Lebanon, ALL★STAR SPORT offers you the highest quality in the market for the lowest price"
          }
          imageUrl={""}
          Logo={Logo}
          url={baseUrl}
          siteName={"ALL★STAR SPORT"}
          keyword={keywords.join(", ")}
        > */}
        <App />
        {/* </SeoDetails> */}
        <Toaster />
      </Provider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <Provider store={store}>
        {/* <MessengerCustomerChat
        pageId="690150731108178"
        appId="1404016446914796"
      /> */}
        {/* <SeoDetails
          name={""}
          description={
            "ALL★STAR SPORT is the one stop shop for all your sporting goods and equipment. One of the leading Sports Retailer & Wholesaler in Lebanon, ALL★STAR SPORT offers you the highest quality in the market for the lowest price"
          }
          imageUrl={""}
          Logo={Logo}
          url={baseUrl}
          siteName={"ALL★STAR SPORT"}
          keyword={keywords.join(", ")}
        > */}
        <App />
        {/* </SeoDetails> */}
        <Toaster />
      </Provider>
    </React.StrictMode>,
    rootElement
  );
}
