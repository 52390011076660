export const RouterName = {
  auth: {
    login: "/login",
    register: "/register",
    activateAccount: "/activate-account/:token",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password/:token",
  },
  home: {
    home: "/",
    category: "/category",
    item: "/item/:id",
    items: "/items",
    cart: "/cart",
    compare: "/compare",
    checkout: "/checkout",
    wishlist: "/wishlist",
    orderSuccess: "/order-success/:id",
    privacyPolicy: "/privacy-policy",
    ErrorRoute: "/404",
  },
  my_account: {
    address: "/my-account/customer-address",
    addressmanagement: "/my-account/address-management",
    my_accountDetails: "/my-account/customer-account-details",
    edit_account: "/account-edit",
    order: "/my-account/customer-order",
  },
};
