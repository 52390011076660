const UrlApi = {
  baseUrl: `${process.env.REACT_APP_BASEURL}/allsportstar/`,
  baseUrlImage: `${process.env.REACT_APP_BASEURL}/uploads_allsportstar/`,
  auth: {
    login: "auth/login",
    autologin: "auth/autologin",
    signup: "auth/register",
    logout: "auth/logout",
    activateAccount: "auth/activate-account",
    forgotPassword: "auth/forgot-password",
    resetPassword: "auth/reset-password",
  },
  user: {
    getAddress: "users/address",
    manageAddress: "users/address/manage",
    editProfile: "auth/edit-profile",
  },
  city: {
    get: "city/get",
  },
  category: {
    get: "category/get",
  },
  item: {
    get: "item/user/get",
    getProduct: "item/user/getProduct",
    getReleatedProduct: "item/user/getReleatedProduct",
    getFeaturedProduct: "item/user/getFeaturedProduct",
    getHotProduct: "item/user/getHotProduct",
    getSizes: "item/user/get_sizes",
  },
  slidershow: {
    get: "slidershow/get",
  },
  order: {
    get: "order/get_order",
    getDetails: "order/get_order_details",
  },
  brands: {
    get: "brands/get",
  },
  favorite: {
    get: "favorite/get",
    manage: "favorite/manage",
  },
  settings: {
    get: "settings/get",
  },
  checkout: {
    checkItem: "checkout/checkItem",
    placeorder: "checkout/placeorder",
  },
  subscription: {
    subscription: "subscription",
    unsubscription: "unsubscription",
  },
};

export default UrlApi;
